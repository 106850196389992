
const axios = require('axios')
const BigNumber = require('bignumber.js')
var PREMIUMS = {}

function normalizeRow (rows) {
  return rows.map((row) => {
    if(row && row.v != null){
      return row
    }

    return {}
  })
}

function applyHeaderIntoRows (header, rows) {
  return rows
    .map(({ c: row }) => normalizeRow(row))
    .map((row) => row.reduce((p, c, i) => c.f ? Object.assign(p, { [header[i]]: c.v }) : p, {}))
}

function getItems (spreadsheetResponse) {
  let rows = []

  try {
    const parsedJSON = JSON.parse(spreadsheetResponse.split('\n')[1].replace(/google.visualization.Query.setResponse\(|\);/g, ''))
    const hasSomeLabelPropertyInCols = parsedJSON.table.cols.some(({ label }) => !!label)
    if (hasSomeLabelPropertyInCols) {
      const header = parsedJSON.table.cols.map(({ label }) => label)

      rows = applyHeaderIntoRows(header, parsedJSON.table.rows)
    } else {
      const [headerRow, ...originalRows] = parsedJSON.table.rows
      const header = normalizeRow(headerRow.c).map((row) => row.v)

      rows = applyHeaderIntoRows(header, originalRows)
    }
  } catch (e) {
    console.log(e)
  }

  return rows
}

async function UpdatePrices() {
  try{
    const parser = await axios.create().get('https://docs.google.com/spreadsheets/d/1jiqNZSTkv9RrWgNCaFdfxpkZxUnjjt2UXWVjs3DOl24/gviz/tq?')
    var sheet_value = getItems(parser.data)

    PREMIUMS = {
       "GOLD_BUY_PREMIUM":{
          "AE": new BigNumber(sheet_value[0].BUY).toFixed(),
          "BUFFALO": new BigNumber(sheet_value[1].BUY).toFixed(),
          "KRUGERRAND": new BigNumber(sheet_value[2].BUY).toFixed(),
          "MAPLELEAF": new BigNumber(sheet_value[3].BUY).toFixed(),
          "GOLDBAR": new BigNumber(sheet_value[4].BUY).toFixed(),
          "FIFTYPESO": new BigNumber(sheet_value[5].BUY).toFixed(),
       },
       "GOLD_SELL_PREMIUM":{
         "AE": new BigNumber(sheet_value[0].SELL).toFixed(),
         "BUFFALO": new BigNumber(sheet_value[1].SELL).toFixed(),
         "KRUGERRAND": new BigNumber(sheet_value[2].SELL).toFixed(),
         "MAPLELEAF": new BigNumber(sheet_value[3].SELL).toFixed(),
         "GOLDBAR": new BigNumber(sheet_value[4].SELL).toFixed(),
         "FIFTYPESO": new BigNumber(sheet_value[5].SELL).toFixed(),
       },
       "SILVER_BUY_PREMIUM":{
         "AE": new BigNumber(sheet_value[6].BUY).toFixed(),
         "ROUND": new BigNumber(sheet_value[7].BUY).toFixed(),
         "ONEHUNDREDBAR": new BigNumber(sheet_value[8].BUY).toFixed(),
         "NINETYPERCENT": new BigNumber(sheet_value[9].BUY).toFixed(),
         "FOURTYPERCENT": new BigNumber(sheet_value[10].BUY).toFixed(),
         "DOLLAR": new BigNumber(sheet_value[11].BUY).toFixed(),
       },
       "SILVER_SELL_PREMIUM":{
         "AE": new BigNumber(sheet_value[6].SELL).toFixed(),
         "ROUND": new BigNumber(sheet_value[7].SELL).toFixed(),
         "ONEHUNDREDBAR": new BigNumber(sheet_value[8].SELL).toFixed(),
         "NINETYPERCENT": new BigNumber(sheet_value[9].SELL).toFixed(),
         "FOURTYPERCENT": new BigNumber(sheet_value[10].SELL).toFixed(),
         "DOLLAR": new BigNumber(sheet_value[11].SELL).toFixed(),
       }
    }

    console.log(PREMIUMS)

    let yourDate = new Date()
    const offset = yourDate.getTimezoneOffset()
    yourDate = new Date(yourDate.getTime() - (offset*60*1000))
    let dateOffset = yourDate.toISOString().split('T')[0]
    console.log(dateOffset)

    const price_data = await axios.create().get('https://tradingviewbot-tau.vercel.app/api/prices/handler')
    var goldPrice = new BigNumber(price_data.data.body.gold_price)
    var silverPrice = new BigNumber(price_data.data.body.silver_price)

    var goldChangePrice = new BigNumber(price_data.data.body.gold_change_price)

    var silverChangePrice = new BigNumber(price_data.data.body.silver_change_price)

    console.log(goldPrice.toFixed())
    console.log(goldChangePrice.toFixed())
    console.log(silverPrice.toFixed())
    console.log(silverChangePrice.toFixed())

    return {
      gold: goldPrice.toFixed(2),
      goldChange: goldChangePrice.gte(0),
      silver: silverPrice.toFixed(2),
      silverChange: silverChangePrice.gte(0),
      1: UpdateItemPricesBuy('1', goldPrice, silverPrice),
      2: UpdateItemPricesBuy('2', goldPrice, silverPrice),
      3: UpdateItemPricesBuy('3', goldPrice, silverPrice),
      4: UpdateItemPricesBuy('4', goldPrice, silverPrice),
      5: UpdateItemPricesBuy('5', goldPrice, silverPrice),
      6: UpdateItemPricesBuy('6', goldPrice, silverPrice),
      7: UpdateItemPricesBuy('7', goldPrice, silverPrice),
      8: UpdateItemPricesBuy('8', goldPrice, silverPrice),
      9: UpdateItemPricesBuy('9', goldPrice, silverPrice),
      10: UpdateItemPricesBuy('10', goldPrice, silverPrice),
      11: UpdateItemPricesBuy('11', goldPrice, silverPrice),
      12: UpdateItemPricesBuy('12', goldPrice, silverPrice),
      13: UpdateItemPrices('1', goldPrice, silverPrice),
      14: UpdateItemPrices('2', goldPrice, silverPrice),
      15: UpdateItemPrices('3', goldPrice, silverPrice),
      16: UpdateItemPrices('4', goldPrice, silverPrice),
      17: UpdateItemPrices('5', goldPrice, silverPrice),
      18: UpdateItemPrices('6', goldPrice, silverPrice),
      19: UpdateItemPrices('7', goldPrice, silverPrice),
      20: UpdateItemPrices('8', goldPrice, silverPrice),
      21: UpdateItemPrices('9', goldPrice, silverPrice),
      22: UpdateItemPrices('10', goldPrice, silverPrice),
      23: UpdateItemPrices('11', goldPrice, silverPrice),
      24: UpdateItemPrices('12', goldPrice, silverPrice),
    }

  } catch(e){
    console.log(e)
    return {
      gold: '0',
      goldChange: true,
      silver: '0',
      silverChange: true,
      1: '0',
      2: '0',
      3: '0',
      4: '0',
      5: '0',
      6: '0',
      7: '0',
      8: '0',
      9: '0',
      10: '0',
      11: '0',
      12: '0',
      13: '0',
      14: '0',
      15: '0',
      16: '0',
      17: '0',
      18: '0',
      19: '0',
      20: '0',
      21: '0',
      22: '0',
      23: '0',
      24: '0',
    }
  }

  //params = products.nextPageParameters;
}

function UpdateItemPrices(sku: String, goldPrice: typeof BigNumber, silverPrice: typeof BigNumber){
  //gold american eagle
  if(sku == '1'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_SELL_PREMIUM['AE']).div(100).times(goldPrice).toFixed(0)
  }
  //gold buffalo
  if(sku == '2'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_SELL_PREMIUM['BUFFALO']).div(100).times(goldPrice).toFixed(0)
  }
  //gold krugerrand
  if(sku == '3'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_SELL_PREMIUM['KRUGERRAND']).div(100).times(goldPrice).toFixed(0)
  }
  //gold maple leaf
  if(sku == '4'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_SELL_PREMIUM['MAPLELEAF']).div(100).times(goldPrice).toFixed(0)
  }
  //gold bar
  if(sku == '5'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_SELL_PREMIUM['GOLDBAR']).div(100).times(goldPrice).toFixed(0)
  }
  //gold 50 peso
  if(sku == '6'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_SELL_PREMIUM['FIFTYPESO']).div(100).times(goldPrice).times(1.2).toFixed(0)
  }
  //silver american eagle
  if(sku == '7'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_SELL_PREMIUM['AE']).div(100).times(silverPrice).toFixed(2)
  }
  //silver round
  if(sku == '8'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_SELL_PREMIUM['ROUND']).div(100).times(silverPrice).toFixed(2)
  }
  //silver 100oz bar
  if(sku == '9'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_SELL_PREMIUM['ONEHUNDREDBAR']).div(100).times(silverPrice).toFixed(2)
  }
  //silver 90%
  if(sku == '10'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_SELL_PREMIUM['NINETYPERCENT']).div(100).times(silverPrice).toFixed(2)
  }
  //silver 40%
  if(sku == '11'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_SELL_PREMIUM['FOURTYPERCENT']).div(100).times(silverPrice).toFixed(2)
  }
  //silver dollar
  if(sku == '12'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_SELL_PREMIUM['DOLLAR']).div(100).times(silverPrice).toFixed(2)
  }

}


function UpdateItemPricesBuy(sku: String, goldPrice: typeof BigNumber, silverPrice: typeof BigNumber){
  //gold american eagle
  if(sku == '1'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_BUY_PREMIUM['AE']).div(100).times(goldPrice).toFixed(0)
  }
  //gold buffalo
  if(sku == '2'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_BUY_PREMIUM['BUFFALO']).div(100).times(goldPrice).toFixed(0)
  }
  //gold krugerrand
  if(sku == '3'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_BUY_PREMIUM['KRUGERRAND']).div(100).times(goldPrice).toFixed(0)
  }
  //gold maple leaf
  if(sku == '4'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_BUY_PREMIUM['MAPLELEAF']).div(100).times(goldPrice).toFixed(0)
  }
  //gold bar
  if(sku == '5'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_BUY_PREMIUM['GOLDBAR']).div(100).times(goldPrice).toFixed(0)
  }
  //gold 50 peso
  if(sku == '6'){
    return new BigNumber(100).plus(PREMIUMS.GOLD_BUY_PREMIUM['FIFTYPESO']).div(100).times(goldPrice).times(1.2).toFixed(0)
  }
  //silver american eagle
  if(sku == '7'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_BUY_PREMIUM['AE']).div(100).times(silverPrice).toFixed(2)
  }
  //silver round
  if(sku == '8'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_BUY_PREMIUM['ROUND']).div(100).times(silverPrice).toFixed(2)
  }
  //silver 100oz bar
  if(sku == '9'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_BUY_PREMIUM['ONEHUNDREDBAR']).div(100).times(silverPrice).toFixed(2)
  }
  //silver 90%
  if(sku == '10'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_BUY_PREMIUM['NINETYPERCENT']).div(100).times(silverPrice).toFixed(2)
  }
  //silver 40%
  if(sku == '11'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_BUY_PREMIUM['FOURTYPERCENT']).div(100).times(silverPrice).toFixed(2)
  }
  //silver dollar
  if(sku == '12'){
    return new BigNumber(100).plus(PREMIUMS.SILVER_BUY_PREMIUM['DOLLAR']).div(100).times(silverPrice).toFixed(2)
  }

}


export default UpdatePrices;
