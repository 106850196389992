import logo from './logo.svg';
import React from 'react';
import './App.css';
import styled from "styled-components";
import UpdatePrices from './main'
import BigNumber from 'bignumber.js';


function App() {

  const [coinObject, setCoinObjet] = React.useState({
    gold: '0',
    silver: '0',
    goldChange: true,
    silverChange: true,
    1: '0',
    2: '0',
    3: '0',
    4: '0',
    5: '0',
    6: '0',
    7: '0',
    8: '0',
    9: '0',
    10: '0',
    11: '0',
    12: '0',
    13: '0',
    14: '0',
    15: '0',
    16: '0',
    17: '0',
    18: '0',
    19: '0',
    20: '0',
    21: '0',
    22: '0',
    23: '0',
    24: '0',
  })


  React.useEffect(() => {

    UpdatePrices().then(res => {
      setCoinObjet(res)
    })

    const interval = setInterval(async () => {
      setCoinObjet(await UpdatePrices())
    }, 300000);


  }, []);

  const getDate = () => {
    let ts = Date.now();

    let date_ob = new Date(ts);
    let date = date_ob.getDate();
    let month = date_ob.getMonth() + 1;
    let year = date_ob.getFullYear();

    // prints date & time in YYYY-MM-DD format
    return month + "/" +date +  "/" + year
  }

  const getTime = () => {
    let date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }


  return (
    <Container>
    <ContentBoxNav>
      <ContentTitle>Type</ContentTitle>
      <ContentTitle>Buy</ContentTitle>
      <ContentTitle>Sell (cash)</ContentTitle>
      <ContentTitle>Sell (credit)</ContentTitle>
      <ContentTitle>Spot Prices</ContentTitle>
    </ContentBoxNav>
    <ContentBox1>
      <ContentGoldText>AE</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[1]}</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[13] }</ContentGoldText>
      <ContentGoldText>{'$' + new BigNumber(coinObject[13]).times(1.035).toFixed(0)}</ContentGoldText>
      {coinObject.goldChange == true ? (
        <ContentGreen>{'$' + coinObject.gold}</ContentGreen>
      ) : (
        <ContentTitle><ContentRed>{'$' + coinObject.gold}</ContentRed></ContentTitle>
       
      )}

    </ContentBox1>
    <ContentBox2>
      <ContentGoldText>Buffalo</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[2]}</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[14]}</ContentGoldText>
      <ContentGoldText>{'$' + new BigNumber(coinObject[14]).times(1.035).toFixed(0)}</ContentGoldText>
      <IndicationText></IndicationText>
    </ContentBox2>
    <ContentBox3>
      <ContentGoldText>Kruggerrand</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[3]}</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[15]}</ContentGoldText>
      <ContentGoldText>{'$' + new BigNumber(coinObject[15]).times(1.035).toFixed(0) }</ContentGoldText>
      <IndicationText>* Indication only - prices based on supply and demand</IndicationText>
    </ContentBox3>
    <ContentBox4>
      <ContentGoldText>MapleLeaf</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[4]}</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[16]}</ContentGoldText>
      <ContentGoldText>{'$' + new BigNumber(coinObject[16]).times(1.035).toFixed(0)}</ContentGoldText>
      <IndicationText></IndicationText>
    </ContentBox4>
    <ContentBox5>
      <ContentGoldText>Gold Bar</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[5]}</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[17]}</ContentGoldText>
      <ContentGoldText>{'$' +  new BigNumber(coinObject[17]).times(1.035).toFixed(0)}</ContentGoldText>
      <ContentGoldText></ContentGoldText>
    </ContentBox5>
    <ContentBox6>
      <ContentGoldText>50 Peso</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[6]}</ContentGoldText>
      <ContentGoldText>{'$' + coinObject[18]}</ContentGoldText>
      <ContentGoldText>{'$' + new BigNumber(coinObject[18]).times(1.035).toFixed(0)}</ContentGoldText>
      <ContentGoldText></ContentGoldText>
    </ContentBox6>
    <ContentBox7>
      <ContentSilverText>AE</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[7]}</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[19]}</ContentSilverText>
      <ContentSilverText>{'$' + new BigNumber(coinObject[19]).times(1.035).toFixed(2)}</ContentSilverText>
      {coinObject.silverChange == true ? (
        <ContentGreen>{'$' + coinObject.silver}</ContentGreen>
      ) : (
        <ContentRed>{'$' + coinObject.silver}</ContentRed>
      )}
    </ContentBox7>
    <ContentBox8>
      <ContentSilverText>Round (1oz)</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[8]}</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[20]}</ContentSilverText>
      <ContentSilverText>{'$' + new BigNumber(coinObject[20]).times(1.035).toFixed(2)}</ContentSilverText>
      <ContentGoldText></ContentGoldText>
    </ContentBox8>
    <ContentBox9>
      <ContentSilverText>100oz Bar</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[9]}</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[21] }</ContentSilverText>
      <ContentSilverText>{'$' + new BigNumber(coinObject[21]).times(1.035).toFixed(2)}</ContentSilverText>
      <ContentGoldText></ContentGoldText>
    </ContentBox9>
    <ContentBox10>
      <ContentSilverText>90% Silver</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[10]}</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[22] }</ContentSilverText>
      <ContentSilverText>{'$' + new BigNumber(coinObject[22]).times(1.035).toFixed(2)}</ContentSilverText>
      <ContentGoldText></ContentGoldText>
    </ContentBox10>
    <ContentBox11>
      <ContentSilverText>40% Silver</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[11]}</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[23]}</ContentSilverText>
      <ContentSilverText>{'$' + new BigNumber(coinObject[23]).times(1.035).toFixed(2)}</ContentSilverText>
      <ContentTitle>{getDate()}</ContentTitle>
    </ContentBox11>
    <ContentBox12>
      <ContentSilverText>Silver Dollar</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[12]}</ContentSilverText>
      <ContentSilverText>{'$' + coinObject[24]}</ContentSilverText>
      <ContentSilverText>{'$' + new BigNumber(coinObject[24]).times(1.035).toFixed(2)}</ContentSilverText>
      <ContentTitle>{getTime()}</ContentTitle>
    </ContentBox12>
    </Container>

  );
}


const Container = styled.div`
  display: grid;
  height: 100vh;
  background: black;
  grid-template-rows: 10vh 7vh 7vh 7vh 7vh 7vh 7vh 7vh 7vh 7vh 7vh 7vh 7vh ;
  grid-template-areas:
    "contentnav contentnav contentnav "
    "content1 content1 content1"
    "content2 content2 content2"
    "content3 content3 content3"
    "content4 content4 content4"
    "content5 content5 content5"
    "content6 content6 content6"
    "content7 content7 content7"
    "content8 content8 content8"
    "content9 content9 content9"
    "content10 content10 content10"
    "content11 content11 content11"
    "content12 content12 content12"
    ;

  text-align: center;
  transition: all 0.25s ease-in-out;
  color: white;
`;

const ContentBoxNav = styled.div`
  display: flex;
  align-items: center;
  grid-area: contentnav;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const ContentBox1 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content1;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const ContentBox2 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content2;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const ContentBox3 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content3;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const ContentBox4 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content4;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const ContentBox5 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content5;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const ContentBox6 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content6;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const ContentBox7 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content7;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const ContentBox8 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content8;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const ContentBox9 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content9;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const ContentBox10 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content10;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const ContentBox11 = styled.div`
  display: flex;


  align-items: center;
  grid-area: content11;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }

`;const ContentBox12 = styled.div`
  display: flex;

  align-items: center;
  grid-area: content12;
  justify-content: center;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const Main = styled.main`
  background: #1f2128;
  color: white;
  grid-area: main;

`;
const SideBar = styled.div`
  background: #9aaab7;
  grid-area: sidebar;

`;

const ContentGoldText = styled.div`
  color: #FFD700;
  padding: rem;
  width: 18vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 48px;
`;
const ContentSilverText = styled(ContentGoldText)`
  color: #a6b8b9;
`;
const ContentRed = styled(ContentGoldText)`
  color: red;
`;
const ContentGreen = styled(ContentGoldText)`
  color: green;
`;
const ContentTitle = styled(ContentGoldText)`
  color: white;
`;

const IndicationText = styled.div`
  color: white;
  width: 18vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 28px;
`;
const Content3 = styled(ContentGoldText)``;
const Footer = styled.footer`
  background: #ff9637;
  grid-area: footer;

`;


export default App;
